
.ant-table {
    background-color: transparent !important;
    margin-top: 15px !important;

}

.ant-table-cell {
    padding: 10px 8px !important;
    border-color: #161B26 !important;

}
.ant-table-placeholder, .ant-table-cell {
    background: #0C111D !important;
}

.ant-table-thead .ant-table-cell {
    background: #161B26 !important;
}

.ant-layout-sider {
    background-color: transparent !important;
}

.ant-tag {
    font-weight: 500;
}

.ant-tag-green {
    color: #75E0A7 !important;
    background-color: #053321 !important;
}

.ant-layout {
    font-family: "Inter", sans-serif;
    background-color: #0C111D !important;
}

.ant-menu {
    padding: 20px 10px;
    font-family: "N27", sans-serif;
    font-size: 16px !important;
    font-weight: 500;
    color: white;
    background-color: transparent !important;
}
.ant-menu-title-content{
    font-size: 16px !important;
}
.ant-modal-title {
    font-family: "N27", sans-serif;
    font-weight: 300;

}

.ant-menu-submenu {
    margin: 4px;
    list-style: none;
}

.ant-menu-item-selected, .ant-menu-submenu {
    background-color: #161850 !important;
}

.ant-menu-submenu-open {
    background-color: #455DFF !important;
}

.ant-menu-title-content > a {
    color: white !important;
}

.ant-menu-item, .ant-menu-submenu {
    border-radius: 2px !important;
    color: white !important;
}

.ant-menu-item:hover {
    background-color: #161850 !important;
}

.ant-btn-primary {
    background-color: #455DFF;
    border-radius: 2px;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 16px;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    line-height: 19px;
}

.ant-btn-default {
    background-color: #161B26;
    color: #999;
    border-color: #333741;
    border-radius: 1px;
    padding-top: 10px;
    padding-bottom: 10px;
    min-height: 36px;
    font-size: 16px;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    line-height: 16px;

}

.ant-form-item-label {
    max-width: 50%;
}
.ant-modal-header{
    background-color: #0C111D !important;
}
.ant-modal-content{
    background-color: #0C111D !important;
}
.ant-modal-content p {
    font-size: 14px;
    font-weight: 400;
}

.ant-btn-default:hover {
    background-color: #455DFF !important;
    color: white !important;
}


.ant-select, .ant-select-selector, .ant-select-open {
    border-radius: 2px !important;
    border-color: #aaa !important;
    width: 160px !important;
    background-color: transparent !important;
    color: #aaa !important;
    font-size: 16px !important;
}

.ant-select-item-option-active {
    background-color: #455DFF !important;
    color: white !important;

}

.ant-select-item-option-selected {
    background-color: #161850 !important;
    color: white !important;

}

.ant-select-dropdown {
    background-color: black;
}

.ant-select-arrow, .ant-select-item-option {
    color: white !important;
}

.ant-card-body {
    padding: 15px 25px !important;
}

.ant-card {
    margin: 15px 0px 40px 0px;
    color: white;
    background-color: #161B26;
    border-color: #1F242F;
    borderRadius: 12px;
}

.ant-skeleton {
    margin-top: 50px !important;

}

.ant-layout-sider-trigger {
    background-color: #161850 !important;
}

.ant-radio-button-checked {
    background-color: #455DFF !important;
}

.ant-radio-button-wrapper-checked > span {
    color: white !important;
}

.ant-divider {
    margin: 15px 0px !important;
}

.ant-btn {
    font-size: 14px;
}


.ant-table-cell {
    padding: 15px 8px !important;
}

.ant-collapse-header{
    margin-top: 40px;
}

.feature-card{
    background-color: #161850;
    border-width: 0;
    border-color: #263283;
    margin-bottom: 10px;
    font-weight: 400;
}

.ant-radio-button-checked {
    background-color: #161B26 !important;
}

.ant-radio-button-wrapper-checked {
    border-color: #333741 !important;
    background-color: #161B26 !important;
}

.ant-radio-button-wrapper {
    background-color: #0C111D !important;
}

:where(.css-dev-only-do-not-override-bhzqiq).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #0C111D !important;
}
